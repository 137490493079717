const LightningIcon = () => {
  const style = {
    stroke:'#fff',
    strokeMiterlimit:10
  }

  return(
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17" style={{height: 12, width: 12}}>
      <path style={style} d="M11.75.57a.5.5,0,0,1,.23.58L10.18,7H13.5a.5.5,0,0,1,.5.5.48.48,0,0,1-.14.34l-8,8.5a.49.49,0,0,1-.7,0A.51.51,0,0,1,5,15.85L6.82,10H3.5A.5.5,0,0,1,3,9.5a.48.48,0,0,1,.14-.34l8-8.5a.49.49,0,0,1,.61-.09Z"/>
    </svg>
  )
}

export default LightningIcon
