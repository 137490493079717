import { useApp } from '../../../context'
import ContactMenu from '../../Menus/Contact'
import { useTransitions } from '../../../hooks'

const HomeContactScreen = () => {
  const { homeScreen5 } = useTransitions();
  const { phoneHeight, phoneWidth, show5, firstLeft, width, page, setPage, top1, contactZ } = useApp();

  return(
    <div className='phone-inner90' style={{width: phoneHeight, height: phoneWidth, opacity: show5 ? 1 : 0, left: firstLeft + 10, bottom: top1, transform: !show5 ? `translate(300%, 0%)` : `translate(0px, 0%)`, transition: homeScreen5(), zIndex: contactZ ? 0 : 3}}>
      <div style={{position: 'relative', height: '100%', width: '100%'}}>
        <div style={{height: '100%', width: width - firstLeft, opacity: (page === 'machine' || page === 'contact' || page === 'about') ? .25 : 1, transition: 'all 650ms ease 600ms'}}>
          <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingLeft: '8%', paddingRight: '8%', flexDirection: 'column', paddingTop: 30, paddingBottom: 30}}>
            <div style={{display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%'}}>
              <div onClick={() => setPage('about')} className='about-btn-lg tfont'>
                <img alt='' src='/Imgs/about.png' style={{width: 100, marginBottom: 4}} />
                About
              </div>
              <div onClick={() => setPage('contact')} className='about-btn-lg tfont'>
                <img alt='' src='/Imgs/contact.png' style={{width: 100, marginBottom: 4}} />
                Contact
              </div>
            </div>
          </div>
        </div>
        <ContactMenu />
      </div>
    </div>
  )
}

export default HomeContactScreen;
