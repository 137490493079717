export const Heart = () => {
  const style = {
    fill:'#5B99D1'
  }
  return(
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 15" style={{width: 16, marginTop: 1, marginLeft: 5}}>
      <path style={style} d="M8.18,1.14,8,1.31l-.18-.17C3.28-3-7.33,4.87,8,15,23.33,4.87,12.72-3,8.18,1.14ZM2.28,3.65a3.25,3.25,0,0,1,5.1-.91L8,3.38l.62-.64a3.25,3.25,0,0,1,5.1.91,4.09,4.09,0,0,1-.27,3.8C12.65,9,11,10.9,8,13,5,10.9,3.35,9,2.55,7.45A4.09,4.09,0,0,1,2.28,3.65Z"/>
    </svg>
  )
}
