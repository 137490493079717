import { useApp } from '../context';
import { useTransitions } from '../hooks';

const Machine = () => {
  const { machineScreen } = useTransitions();
  const { leftoverVertical, page, width } = useApp();
  const isBig = width > 1150;
  const left = isBig ? leftoverVertical : 0;

  return (
    <>
      <div
        style={{
          position: 'absolute',
          paddingLeft: '5%',
          paddingTop: isBig ? '' : '5%',
          left: left,
          top: left,
          maxWidth: isBig ? 720 : '62.5%',
          transform: page === 'machine' ? 'translate(0px, 0px)' : 'translate(0px, 20px)',
          opacity: page === 'machine' ? 1 : 0,
          transition: machineScreen(),
        }}
      >
        <div
          style={{ color: '#5B99D1', fontSize: 40, textShadow: '1px 1px 2px rgba(0,0,0,.17)' }}
          className="tfont"
        >
          Machine Learning
        </div>
        <div
          style={{ color: '#353538', fontSize: 20, lineHeight: 1.1, marginTop: 6 }}
          className="tfont"
        >
          Got data? Let us put it to work for you. Our team comprises several Masters level experts
          in Machine Learning and Data Science who know can turn your data in valuable insights.
        </div>
        <div
          style={{ color: '#353538', fontSize: 20, lineHeight: 1.1, marginTop: 16 }}
          className="tfont"
        >
          Find your strategy gems today
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: 28,
          }}
        >
          <div
            style={{
              width: '45%',
              marginBottom: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              fontSize: 20,
              textShadow: '1px 1px 2px rgba(0,0,0,.17)',
              background: 'white',
              borderRadius: 10,
              paddingTop: 6,
              paddingBottom: 10,
              boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
            }}
          >
            <img
              alt=""
              src="/Imgs/algos.png"
              style={{ width: '50%', borderRadius: 15, marginBottom: 5 }}
            />
            Algorithms
          </div>
          <div
            style={{
              width: '45%',
              marginBottom: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              fontSize: 20,
              textShadow: '1px 1px 2px rgba(0,0,0,.17)',
              background: 'white',
              borderRadius: 10,
              paddingTop: 6,
              paddingBottom: 10,
              boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
            }}
          >
            <img
              alt=""
              src="/Imgs/neurel.png"
              style={{ width: '50%', borderRadius: 15, marginBottom: 5 }}
            />
            Neural Networks
          </div>
          <div
            style={{
              width: '45%',
              marginBottom: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              fontSize: 20,
              textShadow: '1px 1px 2px rgba(0,0,0,.17)',
              background: 'white',
              borderRadius: 10,
              paddingTop: 6,
              paddingBottom: 10,
              boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
            }}
          >
            <img
              alt=""
              src="/Imgs/machineLearning.png"
              style={{ width: '50%', borderRadius: 15, marginBottom: 5 }}
            />
            Machine Learning
          </div>
          <div
            style={{
              width: '45%',
              marginBottom: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              fontSize: 20,
              textShadow: '1px 1px 2px rgba(0,0,0,.17)',
              background: 'white',
              borderRadius: 10,
              paddingTop: 6,
              paddingBottom: 10,
              boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
            }}
          >
            <img
              alt=""
              src="/Imgs/deep.png"
              style={{ width: '50%', borderRadius: 15, marginBottom: 5 }}
            />
            Deep Learning
          </div>
        </div>
      </div>
    </>
  );
};

export default Machine;
