import { useApp } from '../context';

export const useTransitions = () => {
  const { page, resizing, width } = useApp();

  const homeFrame1 = () => {
    if (resizing && width > 1145 && width < 1155) {
      return 'all 650ms ease'
    } else if (resizing) {
      return 'all 0ms linear'
    } else if (page === 'machine' || page === 'contact' || page === 'about') {
      return 'all 650ms ease'
    } else if (page === 'web' || page === 'mobile') {
      return 'all 650ms ease 600ms'
    } else {
      return 'all 650ms ease 1200ms'
    }
  }

  const homeFrame2 = () => {
    if (resizing && width > 1145 && width < 1155) {
      return 'all 650ms ease'
    } else if (resizing) {
      return 'all 0ms linear'
    } else if (page === 'machine' || page === 'contact' || page === 'about') {
      return 'all 650ms ease'
    } else {
      return 'all 650ms ease 300ms'
    }
  }

  const homeFrame3 = () => {
    if (resizing && width > 1145 && width < 1155) {
      return 'all 650ms ease'
    } else if (resizing) {
      return 'all 0ms linear'
    } else if (page === 'machine' || page === 'web' || page === 'mobile' || page === 'contact' || page === 'about') {
      return 'all 650ms ease'
    } else {
      return 'all 650ms ease 600ms'
    }
  }

  const homeFrame4 = () => {
    if (resizing && width > 1145 && width < 1155) {
      return 'all 650ms ease'
    } else if (resizing) {
      return 'all 0ms linear'
    } else if (page === 'web' || page === 'mobile' || page === 'about' || page === 'contact') {
      return 'all 650ms ease'
    } else if (page === 'machine') {
      return 'all 650ms ease 600ms'
    } else {
      return 'all 650ms ease 900ms'
    }
  }

  const homeFrame5 = () => {
    if (resizing && width > 1145 && width < 1155) {
      return 'all 650ms ease'
    } else if (resizing) {
      return 'all 0ms linear'
    } else if (page === 'web' || page === 'mobile') {
      return 'all 650ms ease'
    } else if (page === 'machine') {
      return 'all 650ms ease 900ms'
    } else {
      return 'all 650ms ease 1200ms'
    }
  }

  const homeScreen1 = () => {
    if (page === 'machine' || page === 'contact' || page === 'about' || resizing) {
      return 'transform 650ms ease, z-index 50ms linear 300ms, opacity 400ms ease'
    } else if (page === 'web' || page === 'mobile') {
      return 'all 650ms ease 600ms'
    } else {
      return 'transform 650ms ease 1200ms, z-index 50ms linear 1500ms, opacity 400ms ease 1200ms'
    }
  }

  const homeScreen2 = () => {
    if (page === 'web') {
      return 'transform 650ms ease 300ms, z-index 50ms linear 1500ms, opacity 400ms ease 300ms'
    } else if (page === 'machine' || page === 'contact' || page === 'about' || resizing) {
      return 'transform 650ms ease, z-index 50ms linear 300ms, opacity 400ms ease'
    } else {
      return 'transform 650ms ease 300ms, z-index 50ms linear 0ms, opacity 400ms ease'
    }
  }

  const homeScreen3 = () => {
    if (resizing && width > 1145 && width < 1155) {
      return 'all 650ms ease'
    } else if (resizing) {
      return 'all 50ms linear'
    } else if (page === 'machine' || page === 'web' || page === 'mobile' || page === 'contact' || page === 'about') {
      return 'all 650ms ease'
    } else {
      return 'all 650ms ease 600ms'
    }
  }

  const homeScreen4 = () => {
    if (resizing && width > 1145 && width < 1155) {
      return 'all 650ms ease'
    } else if (resizing) {
      return 'all 0ms linear'
    } else if (page === 'web' || page === 'mobile' || page === 'about' || page === 'contact') {
      return 'all 650ms ease'
    } else if (page === 'machine') {
      return 'all 650ms ease 600ms'
    } else {
      return 'all 650ms ease 900ms'
    }
  }

  const homeScreen5 = () => {
    if (resizing && width > 1145 && width < 1155) {
      return 'all 650ms ease'
    } else if (resizing) {
      return 'all 0ms linear'
    } else if (page === 'web' || page === 'mobile') {
      return 'all 650ms ease'
    } else if (page === 'machine') {
      return 'transform 650ms ease 900ms, z-index 50ms linear 0ms, opacity 400ms ease 900ms'
    } else {
      return 'transform 650ms ease 1200ms, z-index 50ms linear 0ms, opacity 400ms ease 1200ms'
    }
  }

  const contactScreen1 = () => {
    if (page !== 'contact' || resizing) {
      return 'all 650ms ease'
    } else {
      return 'all 650ms ease 1000ms'
    }
  }

  const contactScreen2 = () => {
    if (page !== 'contact' || resizing) {
      return 'all 650ms ease'
    } else {
      return 'all 650ms ease 800ms'
    }
  }

  const contactScreen3 = () => {
    if (page !== 'contact' || resizing) {
      return 'all 900ms ease'
    } else {
      return 'all 650ms ease 2000ms'
    }
  }

  const aboutScreen1 = () => {
    if (page !== 'about' || resizing) {
      return 'all 650ms ease'
    } else {
      return 'all 650ms ease 650ms'
    }
  }

  const aboutScreen2 = () => {
    if (resizing) {
      return 'all 650ms ease'
    } else if (page !== 'about') {
      return 'all 650ms ease 250ms'
    } else {
      return 'all 650ms ease 900ms'
    }
  }

  const aboutScreen3 = () => {
    if (resizing) {
      return 'all 650ms ease'
    } else if (page !== 'about') {
      return 'all 650ms ease 500ms'
    } else {
      return 'all 650ms ease 1150ms'
    }
  }

  const machineScreen = () => {
    if (page !== 'machine' || resizing) {
      return 'all 900ms ease'
    } else {
      return 'all 900ms ease 800ms'
    }
  }

  const mobileScreen = () => {
    if (page !== 'mobile' || resizing) {
      return 'all 900ms ease'
    } else {
      return 'all 900ms ease 800ms'
    }
  }

  const webScreen = () => {
    if (page !== 'web' || resizing) {
      return 'all 900ms ease'
    } else {
      return 'all 900ms ease 800ms'
    }
  }

  const webMenu = () => {
    if (page === 'mobile' || resizing) {
      return 'transform 1000ms ease'
    } else {
      return 'transform 1000ms ease 600ms'
    }
  }

  const mobileMenu = () => {
    if (resizing || page !== 'web') {
      return 'transform 1000ms ease'
    } else {
      return 'transform 1000ms ease 600ms'
    }
  }

  const contactMenu = () => {
    if (resizing || page === 'home') {
      return 'transform 1000ms ease'
    } else {
      return 'transform 1000ms ease 600ms'
    }
  }

  return { homeFrame1, homeFrame2, homeScreen1, homeScreen2, homeFrame3, homeScreen4, homeFrame4, homeFrame5, homeScreen3, contactScreen1, contactScreen2, contactScreen3, aboutScreen1, aboutScreen2, aboutScreen3, homeScreen5, machineScreen, mobileScreen, webScreen, webMenu, mobileMenu, contactMenu }
}
