import { useApp } from '../../../context'
import { useTransitions } from '../../../hooks'

import WebMenu from '../../Menus/Web'

const HomeWebScreen = () => {
  const { homeScreen1 } = useTransitions()
  const { phoneHeight, phoneWidth, show1, firstLeft, leftoverVertical, page, width, smallSpacer } = useApp();
  const top1 = width > 1150 ? leftoverVertical : smallSpacer

  return(
    <div className='phone-inner90' style={{width: phoneHeight - 10, height: phoneWidth - 30, opacity: show1 ? 1 : 0, right: firstLeft, top: top1 + 10, transform: !show1 ? `translate(-1000px, 0%)` : `translate(0%, 0%)`, transition: homeScreen1(), display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', zIndex: page === 'mobile' ? 3 : ''}}>
      <div style={{position: 'relative', height: '100%', width: '100%'}}>
        <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%',height: '100%'}}>
          <div style={{width: width - firstLeft, display: 'flex', alignItems: 'center', flexDirection: 'column', paddingRight: 10}}>
            <img alt='' src='/Imgs/web.png' style={{width: '95%', opacity: page === 'mobile' ? .25 : 1, transition: 'all 650ms ease 600ms'}}/>
            <div style={{color: '#5B99D1', textAlign: 'center', fontSize: 34, textShadow: '1px 1px 2px rgba(0,0,0,.17)', opacity: page === 'mobile' ? .25 : 1, transition: 'all 650ms ease 600ms'}} className='tfont'>Websites</div>
          </div>
        </div>
        <WebMenu />
      </div>
    </div>
  )
}

export default HomeWebScreen
