import SiteWrapper from './context';
import Site from './pages'

function App() {
  return (
    <SiteWrapper>
      <Site />
    </SiteWrapper>
  );
}

export default App;
