import { useApp } from '../context';
import { useTransitions } from '../hooks';

const Mobile = () => {
  const { mobileScreen } = useTransitions();
  const { leftoverVertical, firstLeft, width, page } = useApp();
  const isBig = width > 1150;

  return (
    <>
      <div
        style={{
          position: 'absolute',
          paddingLeft: '5%',
          marginRight: '5%',
          left: width - firstLeft,
          top: isBig ? leftoverVertical : '10%',
          maxWidth: 720,
          transform: page === 'mobile' ? 'translate(0px, 0px)' : 'translate(0px, 20px)',
          opacity: page === 'mobile' ? 1 : 0,
          transition: mobileScreen(),
        }}
      >
        <div
          style={{
            color: '#5B99D1',
            fontSize: width > 960 ? 40 : 36,
            textShadow: '1px 1px 2px rgba(0,0,0,.17)',
          }}
          className="tfont"
        >
          We Live and Breath Mobile Apps
        </div>
        <div
          style={{ color: '#353538', fontSize: 20, lineHeight: 1.1, marginTop: 6 }}
          className="tfont"
        >
          This translates to a unique ability to create custom apps quickly and inexpensively. Have
          the next best app idea? We want to build it for you. Whether it's iOS, Andriod are both
          our team has the skills to take your project from idea to App Store.
        </div>
        <div
          style={{ color: '#353538', fontSize: 20, lineHeight: 1.1, marginTop: 16 }}
          className="tfont"
        >
          What are you waiting for? Let's get started today
        </div>
        <div
          style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', marginTop: 28 }}
        ></div>
      </div>
    </>
  );
};

export default Mobile;
