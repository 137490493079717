const BatteryTip = () => {
  const style = {
    stroke:'#fff',
    strokeMiterlimit:10,
    opacity:0.8
  }
  return(
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6.54 10.99" style={{height: 5}}>
      <path style={style} d="M.52.49H1a5,5,0,0,1,5,5v0a5,5,0,0,1-5,5H.52a0,0,0,0,1,0,0V.49A0,0,0,0,1,.52.49Z"/>
    </svg>
  )
}

export default BatteryTip
