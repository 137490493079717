import { useApp } from '../context';
import { useTransitions } from '../hooks';
import { BsBriefcase } from 'react-icons/bs';
import LaSVG from '../components/LaIcon';
import { Heart } from '../components/Heart';

const About = () => {
  const { aboutScreen1, aboutScreen2, aboutScreen3 } = useTransitions();
  const {
    aboutLeft,
    aboutTop,
    show7,
    aboutWidth,
    aboutHeight,
    width,
    firstLeft,
    page,
    leftoverVertical,
  } = useApp();
  const isBig = width > 1150;

  return (
    <>
      <img
        alt=""
        src="/Imgs/phone.png"
        style={{
          opacity: !show7 ? 0 : 1,
          height: aboutHeight,
          left: aboutLeft,
          top: aboutTop * 3,
          transform: !show7 ? 'translate(0%, -200%)' : 'translate(0%, 0%)',
          transition: aboutScreen1(),
        }}
        className="phone"
      />
      <img
        alt=""
        src="/Imgs/phone.png"
        style={{
          opacity: !show7 ? 0 : 1,
          height: aboutHeight,
          left: aboutLeft * 2 + aboutWidth,
          top: aboutTop * 2,
          transform: !show7 ? 'translate(0%, -200%)' : 'translate(0%, 0%)',
          transition: aboutScreen2(),
        }}
        className="phone"
      />
      <img
        alt=""
        src="/Imgs/phone.png"
        style={{
          opacity: !show7 ? 0 : 1,
          height: aboutHeight,
          left: aboutLeft * 3 + aboutWidth * 2,
          top: aboutTop,
          transform: !show7 ? 'translate(0%, -200%)' : 'translate(0%, 0%)',
          transition: aboutScreen3(),
        }}
        className="phone"
      />

      <div
        className="phone-inner"
        style={{
          height: aboutHeight - 10,
          width: aboutWidth,
          left: aboutLeft,
          top: aboutTop * 3,
          transform: !show7 ? 'translate(0%, -200%)' : 'translate(0%, 0%)',
          transition: aboutScreen1(),
          color: '#5B99D1',
          fontSize: isBig ? 28 : 22,
          textShadow: '1px 1px 2px rgba(0,0,0,.17)',
        }}
      >
        <div
          style={{
            position: 'relative',
            marginLeft: '5%',
            width: '90%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            background: 'rgba(91, 153, 209, .9)',
          }}
        >
          <img
            alt=""
            src="/Imgs/card.png"
            style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', zIndex: 1 }}
          />
          <div
            style={{
              width: aboutWidth * 0.4,
              zIndex: 2,
              height: aboutWidth * 0.4,
              borderRadius: '50%',
              background: 'grey',
              boxShadow: '2px 2px 5px rgba(0,0,0,.2)',
              marginTop: '26%',
              marginBottom: '3%',
            }}
          >
            <img alt="" src="/Imgs/jordan.jpeg" style={{ width: '100%', borderRadius: 20 }} />
          </div>
          <div
            className="tfont"
            style={{
              position: 'relative',
              zIndex: 2,
              lineHeight: 1,
              marginBottom: 4,
              marginTop: 2,
            }}
          >
            Jordan Fonthiem
          </div>
          <div
            style={{
              position: 'relative',
              zIndex: 2,
              fontSize: 16,
              color: '#3c3f42',
              textShadow: 'none',
            }}
            className="jc-ac"
          >
            <BsBriefcase style={{ marginRight: 5 }} /> Admin Manager
          </div>
          <div style={{ position: 'relative', zIndex: 2 }}>
            <div className="js-ac" style={{ marginTop: 34 }}>
              <img
                alt=""
                src="/Imgs/tulane.png"
                style={{ width: 34, borderRadius: 4, boxShadow: '2px 2px 5px rgba(0,0,0,.2)' }}
              />
              <div style={{ fontSize: 14, color: 'black', fontWeight: 600, marginLeft: 10 }}>
                Tulane University
                <div style={{ fontSize: 14, color: 'rgba(0,0,0,.8)', fontWeight: 400 }}>
                  MBA, MHA
                </div>
              </div>
            </div>
            <div className="js-ac" style={{ marginTop: 14 }}>
              <img
                alt=""
                src="/Imgs/map.png"
                style={{ width: 34, borderRadius: 4, boxShadow: '2px 2px 5px rgba(0,0,0,.15)' }}
              />
              <div style={{ fontSize: 14, color: 'black', fontWeight: 600, marginLeft: 10 }}>
                Homebase
                <div style={{ fontSize: 14, color: 'rgba(0,0,0,.8)', fontWeight: 400 }}>
                  Washington, DC
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="phone-inner"
        style={{
          height: aboutHeight - 10,
          width: aboutWidth,
          left: aboutLeft * 2 + aboutWidth,
          top: aboutTop * 2,
          transform: !show7 ? 'translate(0%, -200%)' : 'translate(0%, 0%)',
          transition: aboutScreen2(),
          color: '#5B99D1',
          fontSize: isBig ? 28 : 22,
          textShadow: '1px 1px 2px rgba(0,0,0,.17)',
        }}
      >
        <div
          style={{
            position: 'relative',
            marginLeft: '5%',
            width: '90%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            background: 'rgba(91, 153, 209, .9)',
          }}
        >
          <img
            alt=""
            src="/Imgs/card.png"
            style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', zIndex: 1 }}
          />
          <div
            style={{
              width: aboutWidth * 0.4,
              zIndex: 2,
              height: aboutWidth * 0.4,
              borderRadius: '50%',
              background: 'grey',
              boxShadow: '2px 2px 5px rgba(0,0,0,.2)',
              marginTop: '26%',
              marginBottom: '3%',
            }}
          >
            <img alt="" src="/Imgs/harry.png" style={{ width: '100%', borderRadius: 20 }} />
          </div>
          <div
            className="tfont"
            style={{
              position: 'relative',
              zIndex: 2,
              lineHeight: 1,
              marginBottom: 4,
              marginTop: 2,
            }}
          >
            Harry Fox
          </div>
          <div
            style={{
              position: 'relative',
              zIndex: 2,
              fontSize: 16,
              color: '#3c3f42',
              textShadow: 'none',
            }}
            className="jc-ac"
          >
            <BsBriefcase style={{ marginRight: 5 }} /> Frontend Developer
          </div>
          <div
            style={{
              position: 'relative',
              zIndex: 2,
              fontSize: 16,
              color: '#3c3f42',
              textShadow: 'none',
            }}
            className="jc-ac"
          >
            UI/UX Designer
          </div>
          <div style={{ position: 'relative', zIndex: 2 }}>
            <div className="js-ac" style={{ marginTop: 34 }}>
              <img
                alt=""
                src="/Imgs/contactCode.png"
                style={{ width: 34, borderRadius: 4, boxShadow: '2px 2px 5px rgba(0,0,0,.2)' }}
              />
              <div style={{ fontSize: 14, color: 'black', fontWeight: 600, marginLeft: 10 }}>
                Favorite Languages
                <div style={{ fontSize: 14, color: 'rgba(0,0,0,.8)', fontWeight: 400 }}>
                  JavaScript, HTML, CSS
                </div>
              </div>
            </div>
            <div className="js-ac" style={{ marginTop: 14 }}>
              <img
                alt=""
                src="/Imgs/tulane.png"
                style={{ width: 34, borderRadius: 4, boxShadow: '2px 2px 5px rgba(0,0,0,.2)' }}
              />
              <div style={{ fontSize: 14, color: 'black', fontWeight: 600, marginLeft: 10 }}>
                Tulane University
                <div style={{ fontSize: 14, color: 'rgba(0,0,0,.8)', fontWeight: 400 }}>
                  MBA, MS Data Science
                </div>
              </div>
            </div>
            <div className="js-ac" style={{ marginTop: 14 }}>
              <img
                alt=""
                src="/Imgs/map.png"
                style={{ width: 34, borderRadius: 4, boxShadow: '2px 2px 5px rgba(0,0,0,.15)' }}
              />
              <div style={{ fontSize: 14, color: 'black', fontWeight: 600, marginLeft: 10 }}>
                Homebase
                <div style={{ fontSize: 14, color: 'rgba(0,0,0,.8)', fontWeight: 400 }}>
                  New Orleans, LA
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="phone-inner"
        style={{
          height: aboutHeight - 10,
          width: aboutWidth,
          left: aboutLeft * 3 + aboutWidth * 2,
          top: aboutTop,
          transform: !show7 ? 'translate(0%, -200%)' : 'translate(0%, 0%)',
          transition: aboutScreen3(),
          color: '#5B99D1',
          fontSize: isBig ? 28 : 22,
          textShadow: '1px 1px 2px rgba(0,0,0,.17)',
        }}
      >
        <div
          style={{
            position: 'relative',
            marginLeft: '5%',
            width: '90%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            background: 'rgba(91, 153, 209, .9)',
          }}
        >
          <img
            alt=""
            src="/Imgs/card.png"
            style={{ position: 'absolute', left: 0, bottom: 0, width: '100%', zIndex: 1 }}
          />
          <div
            style={{
              width: aboutWidth * 0.4,
              zIndex: 2,
              height: aboutWidth * 0.4,
              borderRadius: '50%',
              background: 'grey',
              boxShadow: '2px 2px 5px rgba(0,0,0,.2)',
              marginTop: '26%',
              marginBottom: '3%',
            }}
          >
            <img alt="" src="/Imgs/reed.jpeg" style={{ width: '100%', borderRadius: 20 }} />
          </div>
          <div
            className="tfont"
            style={{
              position: 'relative',
              zIndex: 2,
              lineHeight: 1,
              marginBottom: 4,
              marginTop: 2,
            }}
          >
            Reed Stephens
          </div>
          <div
            style={{
              position: 'relative',
              zIndex: 2,
              fontSize: 16,
              color: '#3c3f42',
              textShadow: 'none',
            }}
            className="jc-ac"
          >
            <BsBriefcase style={{ marginRight: 5 }} /> Backend Developer
          </div>
          <div
            style={{
              position: 'relative',
              zIndex: 2,
              fontSize: 16,
              color: '#3c3f42',
              textShadow: 'none',
            }}
            className="jc-ac"
          >
            Machine Learning Specialist
          </div>
          <div style={{ position: 'relative', zIndex: 2 }}>
            <div className="js-ac" style={{ marginTop: 34 }}>
              <img
                alt=""
                src="/Imgs/contactCode.png"
                style={{ width: 34, borderRadius: 4, boxShadow: '2px 2px 5px rgba(0,0,0,.2)' }}
              />
              <div style={{ fontSize: 14, color: 'black', fontWeight: 600, marginLeft: 10 }}>
                Favorite Languages
                <div style={{ fontSize: 14, color: 'rgba(0,0,0,.8)', fontWeight: 400 }}>
                  JavaScript, Python
                </div>
              </div>
            </div>
            <div className="js-ac" style={{ marginTop: 14 }}>
              <img
                alt=""
                src="/Imgs/tulane.png"
                style={{ width: 34, borderRadius: 4, boxShadow: '2px 2px 5px rgba(0,0,0,.2)' }}
              />
              <div style={{ fontSize: 14, color: 'black', fontWeight: 600, marginLeft: 10 }}>
                Tulane University
                <div style={{ fontSize: 14, color: 'rgba(0,0,0,.8)', fontWeight: 400 }}>MBA</div>
              </div>
            </div>
            <div className="js-ac" style={{ marginTop: 14 }}>
              <img
                alt=""
                src="/Imgs/gTech.png"
                style={{ width: 34, borderRadius: 4, boxShadow: '2px 2px 5px rgba(0,0,0,.2)' }}
              />
              <div style={{ fontSize: 14, color: 'black', fontWeight: 600, marginLeft: 10 }}>
                Georgia Tech
                <div style={{ fontSize: 14, color: 'rgba(0,0,0,.8)', fontWeight: 400 }}>
                  MS Computer Science
                </div>
              </div>
            </div>
            <div className="js-ac" style={{ marginTop: 14 }}>
              <img
                alt=""
                src="/Imgs/map.png"
                style={{ width: 34, borderRadius: 4, boxShadow: '2px 2px 5px rgba(0,0,0,.15)' }}
              />
              <div style={{ fontSize: 14, color: 'black', fontWeight: 600, marginLeft: 10 }}>
                Homebase
                <div style={{ fontSize: 14, color: 'rgba(0,0,0,.8)', fontWeight: 400 }}>
                  New Orleans, LA
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          position: 'absolute',
          left: firstLeft,
          top: leftoverVertical,
          width: width - firstLeft,
          transform: page === 'about' ? 'translate(0px, 0px)' : 'translate(0px, 20px)',
          opacity: page === 'about' ? 1 : 0,
          transition: aboutScreen3(),
        }}
      >
        <div
          style={{ color: '#5B99D1', fontSize: 40, textShadow: '1px 1px 2px rgba(0,0,0,.17)' }}
          className="tfont"
        >
          About Us
        </div>
        <div
          style={{
            color: '#353538',
            fontSize: isBig ? 20 : 18,
            lineHeight: 1.1,
            paddingRight: isBig ? '10%' : '7%',
          }}
          className="tfont"
        >
          We are a group of software engineers based out of New Orleans, Lousiana with a passion for
          development & design.
        </div>
        <div
          style={{
            color: '#353538',
            fontSize: isBig ? 20 : 18,
            lineHeight: 1.1,
            paddingRight: isBig ? '10%' : '7%',
            paddingTop: 12,
          }}
          className="tfont"
        >
          We specialize in creating applications for startups and small businesses taking great care
          to craft products that reduce operational and maitenence costs.
        </div>
        <div
          className="tfont jc-ac"
          style={{
            color: '#5B99D1',
            fontSize: isBig ? 20 : 18,
            paddingTop: 10,
            textShadow: '1px 1px 1px rgba(0,0,0,.17)',
          }}
        >
          Made in <LaSVG width={40} color="#5B99D1" stroke="1px" /> With <Heart />
        </div>
      </div>
    </>
  );
};

export default About;
