import { useState, useEffect, useRef } from 'react';
import { useApp } from '../../context';
import { BsTelephoneFill, BsHouse, BsFillChatFill } from 'react-icons/bs';
import LaSVG from '../../components/LaIcon';
import { useScrollPosition } from '../../hooks';
import { Fox } from '../../components/Fox';
import MessageScreen from './Contact';
// import useScreenOrientation from 'react-hook-screen-orientation';

const SiteSmall = () => {
  const { width } = useApp();
  const [siteScreen, setSiteScreen] = useState(0);
  const [colorMode, setColorMode] = useState('');
  const totalHeight = window.innerHeight;
  const bg = colorMode === 'light' ? 'mobileBgLight' : 'mobileBg';
  const [showPhone, setShowPhone] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  document.body.style.overflow = 'hidden';
  const [initialMouseX, setInitialMouseX] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [dragPosition, setDragPosition] = useState(0);
  const halfScreen = width * 0.45;
  const [fastSlide, setFastSlide] = useState(false);
  const scrollPosition = useScrollPosition();
  // const screenOrientation = useScreenOrientation();

  const handleDown = (e) => {
    setFastSlide(true);
    setTimeout(() => setFastSlide(false), 150);
    setInitialMouseX(e.touches[0]?.clientX);
  };

  const handleMove = (e) => {
    setDragging(true);
    setDragPosition(e.touches[0]?.clientX - initialMouseX);
  };

  const handleEnd = (e) => {
    e.stopPropagation();
    setInitialMouseX(0);
    setDragPosition(0);
    setTimeout(() => setDragging(false), 150);
    let threshold = fastSlide ? halfScreen / 3 : halfScreen;
    if (Math.abs(dragPosition) > threshold) {
      if (dragPosition < 0 && siteScreen < 4) {
        setSiteScreen(siteScreen + 1);
      } else if (siteScreen > 0 && dragPosition > 0) {
        setSiteScreen(siteScreen - 1);
      }
    }
  };

  useEffect(() => {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => setColorMode(e.matches ? 'dark' : 'light'));
    setColorMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {});
    };
  }, []);

  useEffect(() => {
    if (scrollPosition !== 0) {
      window.scrollTo({ top: 0, left: 0 });
    }
  }, [scrollPosition]);

  const handleClick = (val) => {
    if (val === 5) {
      setShowPhone(!showPhone);
    } else if (val === 6) {
      setShowMessage(!showMessage);
    } else {
      setSiteScreen(val);
    }
  };

  return (
    <div
      style={{
        width: width,
        height: totalHeight,
        backgroundImage: `url("/Imgs/${bg}.png")`,
        userSelect: 'none',
      }}
      className="mobile-cont"
      onTouchStart={(e) => handleDown(e)}
      onTouchMove={(e) => handleMove(e)}
      onTouchEnd={(e) => handleEnd(e)}
    >
      <HomeScreen
        siteScreen={siteScreen}
        setSiteScreen={handleClick}
        dragPosition={dragPosition}
        dragging={dragging}
        fastSlide={fastSlide}
      />
      <WebScreen siteScreen={siteScreen} dragPosition={dragPosition} dragging={dragging} />
      <MobileScreen
        siteScreen={siteScreen}
        setSiteScreen={handleClick}
        dragPosition={dragPosition}
        dragging={dragging}
      />
      <MachineScreen siteScreen={siteScreen} dragPosition={dragPosition} dragging={dragging} />
      <AboutScreen
        siteScreen={siteScreen}
        handleClick={handleClick}
        dragPosition={dragPosition}
        dragging={dragging}
        fastSlide={fastSlide}
      />
      <CallScreen showPhone={showPhone} handleClick={handleClick} />
      <MessageScreen showMessage={showMessage} handleClick={handleClick} siteScreen={siteScreen} />
      <div>
        <div className="jc-ac" style={{ marginBottom: 14 }}>
          {screenList?.map((s, key) => (
            <div
              key={key}
              style={{ background: siteScreen === s ? 'white' : 'rgba(255, 255, 255, .5)' }}
              className="mobile-screen-btn"
            />
          ))}
        </div>
        <Footer handleClick={handleClick} dragPosition={dragPosition} />
      </div>
    </div>
  );
};

export default SiteSmall;

const Footer = ({ handleClick, dragPosition }) => {
  const handleTouch = () => {
    if (Math.abs(dragPosition) < 25) {
      handleClick(0);
    }
  };

  return (
    <div style={{ paddingLeft: 12, paddingRight: 12 }} className="jc-ac">
      <div
        style={{
          width: '100%',
          maxWidth: 616,
          borderRadius: 28,
          height: 76,
          background: 'rgba(0,0,0,.35)',
          boxShadow: '2px 2px 12px rgba(0,0,0,.12)',
        }}
        className="js-ac"
        onTouchEnd={handleTouch}
      >
        <div
          style={{
            height: 54,
            width: 54,
            borderRadius: 14,
            background: '#5B99D1',
            marginLeft: 12,
            marginRight: 12,
          }}
          className="jc-ac"
        >
          <Fox />
        </div>
        <div>
          <div
            style={{
              fontSize: 30,
              letterSpacing: '-.5mm',
              color: '#5B99D1',
              lineHeight: 1,
              textShadow: '1px 1px 2px rgba(0,0,0,.17)',
            }}
            className="tfont"
          >
            FROBOFOX
          </div>
          <div
            style={{
              fontSize: 18,
              letterSpacing: '-.25mm',
              color: 'white',
              lineHeight: 1,
              textShadow: '1px 1px 2px rgba(0,0,0,.17)',
              marginTop: 4,
              marginBottom: 6,
            }}
            className="tfont"
          >
            Software Development <span style={{ fontStyle: 'italic' }}>Made Easy</span>
          </div>
        </div>
      </div>
    </div>
  );
};

const numbers = [
  { n: 1, s: '' },
  { n: 2, s: 'ABC' },
  { n: 3, s: 'DEF' },
  { n: 4, s: 'GHI' },
  { n: 5, s: 'JKL' },
  { n: 6, s: 'MNO' },
  { n: 7, s: 'PQRS' },
  { n: 8, s: 'TUV' },
  { n: 9, s: 'WXYZ' },
  { n: '*', s: '' },
  { n: 0, s: '+' },
  { n: '#', s: '' },
];
const screenList = [0, 1, 2, 3, 4];
const iconList = [
  { l: 'Websites', i: '/Imgs/webIcon.png', c: 1 },
  { l: 'Mobile Apps', i: '/Imgs/mobileIcon.png', c: 2 },
  { l: 'AI & ML', i: '/Imgs/machineIcon.png', c: 3 },
  { l: 'About Us', i: '/Imgs/aboutIcon.png', c: 4 },
  { l: 'Call Us', i: '/Imgs/callIcon.png', c: 5 },
  { l: 'Write Us', i: '/Imgs/messageIcon.png', c: 6 },
];

const HomeScreen = ({ siteScreen, setSiteScreen, dragPosition, dragging, fastSlide }) => {
  const { width } = useApp();
  const max = fastSlide ? 90 : 22;
  const firstDragPosition = siteScreen === 0 ? Math.min(dragPosition, max) : dragPosition;
  const position = 0 - siteScreen;
  const [lastPosition, setLastPosition] = useState(0);
  const distance = dragging ? 0 : Math.abs(lastPosition - position);
  const transition = `transform ${150 + distance * 100}ms linear`;

  useEffect(() => {
    setLastPosition(() => setLastPosition(position), 600);
    //eslint-disable-next-line
  }, [siteScreen]);

  const handleTouch = (value) => {
    if (Math.abs(dragPosition) < 25) {
      setSiteScreen(value);
    }
  };

  return (
    <div
      style={{
        transform: `translate(${position * width + firstDragPosition}px, 0px)`,
        transition: transition,
      }}
      className="jc-ac mobile-btn-wrap"
    >
      <div className="jb-ac" style={{ flexWrap: 'wrap', width: '100%', maxWidth: 612 }}>
        {iconList?.map((b, key) => (
          <div style={{ width: width * 0.21, maxWidth: 94 }} className="mobile-button" key={key}>
            <div
              style={{
                width: width * 0.17,
                height: width * 0.17,
                maxWidth: 88,
                maxHeight: 88,
                marginBottom: 4,
              }}
              className="jc-ac"
            >
              <img alt="" src={b.i} className="mobile-btn-i" onTouchEnd={() => handleTouch(b.c)} />
            </div>
            {b.l}
          </div>
        ))}
        {width < 700 && <FakeButton />}
        {width < 700 && <FakeButton />}
      </div>
    </div>
  );
};

const WebScreen = ({ siteScreen, dragPosition, dragging }) => {
  const { width } = useApp();
  const show = siteScreen === 1 || dragging;
  const position = 1 - siteScreen;
  const [lastPosition, setLastPosition] = useState(0);
  const distance = dragging ? 0 : Math.abs(lastPosition - position);
  const transition = `transform ${150 + distance * 75}ms linear, opacity ${
    dragging ? 50 : 225
  }ms linear`;

  useEffect(() => {
    setLastPosition(() => setLastPosition(position), 600);
    //eslint-disable-next-line
  }, [siteScreen]);

  return (
    <div
      style={{
        width: width,
        transform: `translate(${position * width + dragPosition}px, 0px)`,
        opacity: show ? 1 : 0,
        transition: transition,
        display: width > 650 ? 'flex' : '',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      className="mobile-screen-wrap"
    >
      <div
        style={{
          width: '100%',
          maxWidth: 612,
          background: 'rgba(0,0,0,.55)',
          borderRadius: 16,
          boxShadow: '2px 2px 12px rgba(0,0,0,.12)',
        }}
      >
        <div
          className="js-ac"
          style={{ paddingLeft: 14, paddingRight: 14, paddingTop: 12, marginTop: -24 }}
        >
          <div
            style={{
              width: 54,
              height: 54,
              borderRadius: 14,
              flexShrink: 0,
              marginRight: 6,
              overflow: 'hidden',
            }}
            className="jc-ac"
          >
            <img alt="" src="/Imgs/webIcon.png" style={{ width: '100%' }} />
          </div>
          <div
            style={{
              paddingLeft: 6,
              fontSize: 28,
              color: '#5B99D1',
              lineHeight: 1,
              textShadow: '1px 1px 2px rgba(255,255,255,.17)',
            }}
            className="tfont"
          >
            Websites Are Our <br />
            <span style={{ fontStyle: 'italic' }}>Passion</span>
          </div>
        </div>
        <div
          style={{
            width: 'calc(100% - 24px)',
            height: 1,
            background: 'rgba(255,255,255,.55)',
            marginLeft: 12,
            marginTop: 12,
          }}
        />
        <div
          style={{
            color: 'white',
            fontSize: 14,
            lineHeight: 1.1,
            paddingTop: 12,
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 12,
          }}
          className="tfont"
        >
          We specailize in creating custom web apps for marketplaces, e-commerce, blockchain,
          finance, no-code and more. Our focus is on building stable sites in ways that set our
          clients up for success and on completing jobs ontime at industry low costs.
        </div>
        <div
          style={{
            color: 'white',
            fontSize: 14,
            lineHeight: 1.1,
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 14,
          }}
          className="tfont"
        >
          Check out a few of our past projects
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: width > 650 ? 'center' : 'space-between',
          flexWrap: 'wrap',
          marginTop: 18,
        }}
      >
        <a
          href="https://bloksapps.com"
          style={{
            width: '47%',
            marginBottom: 18,
            maxWidth: 290,
            marginRight: width > 650 ? 14 : 0,
          }}
          rel="noopener noreferrer"
          target="blank"
          className=""
        >
          <img
            alt=""
            src="/Imgs/bloks.png"
            style={{
              width: '100%',
              borderRadius: 8,
              filter: 'drop-shadow(1px 1px 8px rgba(0, 0, 0, .22))',
            }}
          />
        </a>
        <a
          href="https://wordleweasel.com"
          style={{
            width: '47%',
            marginBottom: 18,
            maxWidth: 290,
            marginLeft: width > 650 ? 14 : 0,
          }}
          rel="noopener noreferrer"
          target="blank"
          className=""
        >
          <img
            alt=""
            src="/Imgs/weasel.png"
            style={{
              width: '100%',
              borderRadius: 8,
              filter: 'drop-shadow(1px 1px 8px rgba(0, 0, 0, .22))',
            }}
          />
        </a>
        <a
          href="https://stockbloks.com"
          style={{
            width: '47%',
            marginBottom: 18,
            maxWidth: 290,
            marginRight: width > 650 ? 14 : 0,
          }}
          rel="noopener noreferrer"
          target="blank"
          className=""
        >
          <img
            alt=""
            src="/Imgs/sbloks.png"
            style={{
              width: '100%',
              borderRadius: 8,
              filter: 'drop-shadow(1px 1px 8px rgba(0, 0, 0, .22))',
            }}
          />
        </a>
        <a
          href="https://shamancoin.org"
          style={{
            width: '47%',
            marginBottom: 18,
            maxWidth: 290,
            marginLeft: width > 650 ? 14 : 0,
          }}
          rel="noopener noreferrer"
          target="blank"
          className=""
        >
          <img
            alt=""
            src="/Imgs/shaman.png"
            style={{
              width: '100%',
              borderRadius: 8,
              filter: 'drop-shadow(1px 1px 8px rgba(0, 0, 0, .22))',
            }}
          />
        </a>
      </div>
    </div>
  );
};

const MobileScreen = ({ siteScreen, setSiteScreen, dragPosition, dragging }) => {
  const { width } = useApp();
  const show = siteScreen === 2 || dragging;
  const position = 2 - siteScreen;
  const [lastPosition, setLastPosition] = useState(0);
  const distance = dragging ? 0 : Math.abs(lastPosition - position);
  const transition = `transform ${150 + distance * 75}ms linear, opacity ${
    dragging ? 50 : 225
  }ms linear`;

  useEffect(() => {
    setLastPosition(() => setLastPosition(position), 600);
    //eslint-disable-next-line
  }, [siteScreen]);

  const handleClick = () => {
    if (Math.abs(dragPosition) < 25) {
      setSiteScreen(5);
    }
  };

  return (
    <div
      style={{
        width: width,
        transform: `translate(${position * width + dragPosition}px, 0px)`,
        opacity: show ? 1 : 0,
        flexDirection: 'column',
        height: 'calc(100% - 136px)',
        transition: transition,
      }}
      className="mobile-screen-wrap jb-ac"
    >
      <div
        style={{
          width: '100%',
          maxWidth: 616,
          background: 'rgba(0,0,0,.55)',
          borderRadius: 16,
          boxShadow: '2px 2px 12px rgba(0,0,0,.12)',
          paddingTop: 12,
          marginTop: -24,
        }}
      >
        <div className="js-ac" style={{ paddingLeft: 14, paddingRight: 14 }}>
          <div
            style={{
              width: 54,
              height: 54,
              borderRadius: 14,
              flexShrink: 0,
              marginRight: 6,
              overflow: 'hidden',
            }}
            className="jc-ac"
          >
            <img alt="" src="/Imgs/mobileIcon.png" style={{ width: '100%' }} />
          </div>
          <div
            style={{
              paddingLeft: 6,
              fontSize: 28,
              color: '#5B99D1',
              lineHeight: 1,
              textShadow: '1px 1px 2px rgba(255,255,255,.17)',
            }}
            className="tfont"
          >
            We Live & Breath
            <br /> Mobile Apps
          </div>
        </div>
        <div
          style={{
            width: 'calc(100% - 24px)',
            height: 1,
            background: 'rgba(255,255,255,.55)',
            marginLeft: 12,
            marginTop: 12,
          }}
        />
        <div
          style={{
            color: 'white',
            fontSize: 14,
            lineHeight: 1.1,
            paddingTop: 12,
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 12,
          }}
          className="tfont"
        >
          This translates to a unique ability to create custom apps quickly and inexpensively. Have
          the next best app idea? We want to build it for you. Wether it's iOS, Andriod are both our
          team has the skills to take your project from idea to App Store.
        </div>
        <div
          style={{
            color: 'white',
            fontSize: 14,
            lineHeight: 1.1,
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 14,
          }}
          className="tfont"
        >
          What are you waiting for? Let's get started today
        </div>
      </div>
      <img alt="" src="/Imgs/mobile.png" style={{ width: '100%', maxWidth: 710 }} />
      <div
        style={{
          width: '100%',
          maxWidth: 616,
          height: 46,
          background: '#5B99D1',
          borderRadius: 14,
          color: 'white',
          fontSize: 22,
          boxShadow: '2px 2px 6px rgba(0,0,0,.2)',
          marginBottom: 6,
          paddingTop: 1,
        }}
        className="jc-ac tfont"
        onTouchEnd={() => handleClick()}
      >
        Launch Yours Today
      </div>
    </div>
  );
};

const MachineScreen = ({ siteScreen, dragPosition, dragging }) => {
  const { width } = useApp();
  const show = siteScreen === 3 || dragging;
  const position = 3 - siteScreen;
  const [lastPosition, setLastPosition] = useState(0);
  const distance = dragging ? 0 : Math.abs(lastPosition - position);
  const delay = siteScreen === 0 ? 175 : 0;
  const transition = `transform ${150 + distance * 75}ms linear, opacity ${
    dragging ? 50 : 225
  }ms linear ${delay}ms`;

  useEffect(() => {
    setLastPosition(() => setLastPosition(position), 600);
    //eslint-disable-next-line
  }, [siteScreen]);

  return (
    <div
      style={{
        width: width,
        transform: `translate(${position * width + dragPosition}px, 0px)`,
        opacity: show ? 1 : 0,
        transition: transition,
        display: width > 650 ? 'flex' : '',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      className="mobile-screen-wrap"
    >
      <div
        style={{
          width: '100%',
          maxWidth: 616,
          background: 'rgba(0,0,0,.55)',
          borderRadius: 16,
          boxShadow: '2px 2px 12px rgba(0,0,0,.12)',
        }}
      >
        <div
          className="js-ac"
          style={{ paddingLeft: 14, paddingRight: 14, paddingTop: 12, marginTop: -24 }}
        >
          <div
            style={{
              width: 54,
              height: 54,
              borderRadius: 14,
              flexShrink: 0,
              marginRight: 6,
              overflow: 'hidden',
            }}
            className="jc-ac"
          >
            <img alt="" src="/Imgs/machineIcon.png" style={{ width: '100%' }} />
          </div>
          <div
            style={{
              paddingLeft: 6,
              fontSize: width < 350 ? 22 : width < 368 ? 24 : width < 385 ? 26 : 28,
              color: '#5B99D1',
              lineHeight: 1,
              textShadow: '1px 1px 2px rgba(255,255,255,.17)',
            }}
            className="tfont"
          >
            Machine Learning &<br /> Artificial Intelligence
          </div>
        </div>
        <div
          style={{
            width: 'calc(100% - 24px)',
            height: 1,
            background: 'rgba(255,255,255,.55)',
            marginLeft: 12,
            marginTop: 12,
          }}
        />
        <div
          style={{
            color: 'white',
            fontSize: 14,
            lineHeight: 1.1,
            paddingTop: 12,
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 12,
          }}
          className="tfont"
        >
          Got data? Let us put it to work for you. Our team comprises several Masters level experts
          in Machine Learning and Data Science who know can turn your data in valuable insights.
        </div>
        <div
          style={{
            color: 'white',
            fontSize: 14,
            lineHeight: 1.1,
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 14,
          }}
          className="tfont"
        >
          Find your strategy gems today
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginTop: 16,
          maxWidth: 616,
        }}
      >
        <div
          style={{
            width: '48%',
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontSize: 18,
            color: 'white',
            textShadow: '1px 1px 2px rgba(0,0,0,.17)',
            background: 'rgba(0,0,0,.55)',
            borderRadius: 10,
            paddingTop: 6,
            paddingBottom: 8,
            boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
          }}
        >
          <img
            alt=""
            src="/Imgs/algoSmall.png"
            style={{ width: '58%', borderRadius: 15, marginBottom: 5, marginTop: 5 }}
          />
          Algorithms
        </div>
        <div
          style={{
            width: '48%',
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontSize: 18,
            color: 'white',
            textShadow: '1px 1px 2px rgba(0,0,0,.17)',
            background: 'rgba(0,0,0,.55)',
            borderRadius: 10,
            paddingTop: 6,
            paddingBottom: 8,
            boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
          }}
        >
          <img
            alt=""
            src="/Imgs/nuerelSmall.png"
            style={{ width: '58%', borderRadius: 15, marginBottom: 5, marginTop: 5 }}
          />
          Neurel Networks
        </div>
        <div
          style={{
            width: '48%',
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontSize: 18,
            color: 'white',
            textShadow: '1px 1px 2px rgba(0,0,0,.17)',
            background: 'rgba(0,0,0,.55)',
            borderRadius: 10,
            paddingTop: 6,
            paddingBottom: 8,
            boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
          }}
        >
          <img
            alt=""
            src="/Imgs/mlSmall.png"
            style={{ width: '58%', borderRadius: 15, marginBottom: 5, marginTop: 5 }}
          />
          Machine Learning
        </div>
        <div
          style={{
            width: '48%',
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontSize: 18,
            color: 'white',
            textShadow: '1px 1px 2px rgba(0,0,0,.17)',
            background: 'rgba(0,0,0,.55)',
            borderRadius: 10,
            paddingTop: 6,
            paddingBottom: 8,
            boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
          }}
        >
          <img
            alt=""
            src="/Imgs/deepSmall.png"
            style={{ width: '58%', borderRadius: 15, marginBottom: 5, marginTop: 5 }}
          />
          Deep Learning
        </div>
      </div>
    </div>
  );
};

const AboutScreen = ({ siteScreen, handleClick, dragPosition, dragging, fastSlide }) => {
  const { width } = useApp();
  const show = siteScreen === 4 || dragging;
  const max = fastSlide ? -94 : -16;
  const lastDragPosition = siteScreen === 4 ? Math.max(dragPosition, max) : dragPosition;
  const reedRed = useRef(null);
  const personHeight = reedRed?.current?.offsetHeight || 103;
  const buttonHeight = personHeight - 54;
  const position = 4 - siteScreen;
  const [lastPosition, setLastPosition] = useState(0);
  const distance = dragging ? 0 : Math.abs(lastPosition - position);
  const delay = siteScreen === 0 ? 175 : 0;
  const transition = `transform ${150 + distance * 75}ms linear, opacity ${
    dragging ? 50 : 225
  }ms linear ${delay}ms`;

  useEffect(() => {
    setLastPosition(() => setLastPosition(position), 600);
    //eslint-disable-next-line
  }, [siteScreen]);

  const handleTouch = (value) => {
    if (Math.abs(dragPosition) < 25) {
      handleClick(value);
    }
  };

  return (
    <div
      style={{
        width: width,
        transform: `translate(${position * width + lastDragPosition}px, 0px)`,
        opacity: show ? 1 : 0,
        transition: transition,
        display: width > 650 ? 'flex' : '',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      className="mobile-screen-wrap"
    >
      <div
        style={{
          width: '100%',
          maxWidth: 616,
          background: 'rgba(0,0,0,.55)',
          borderRadius: 16,
          boxShadow: '2px 2px 12px rgba(0,0,0,.12)',
          paddingBottom: 8,
          paddingTop: 12,
          marginTop: -24,
        }}
      >
        <div className="js-ac" style={{ paddingLeft: 14, paddingRight: 14 }}>
          <div
            style={{
              width: 54,
              height: 54,
              borderRadius: 14,
              flexShrink: 0,
              marginRight: 6,
              overflow: 'hidden',
            }}
            className="jc-ac"
          >
            <img alt="" src="/Imgs/aboutIcon.png" style={{ width: '100%' }} />
          </div>
          <div
            style={{
              paddingLeft: 6,
              fontSize: 28,
              color: '#5B99D1',
              lineHeight: 1,
              textShadow: '1px 1px 2px rgba(255,255,255,.17)',
            }}
            className="tfont"
          >
            About
            <br />
            FROBOFOX
          </div>
        </div>
        <div
          style={{
            width: 'calc(100% - 24px)',
            height: 1,
            background: 'rgba(255,255,255,.55)',
            marginLeft: 12,
            marginTop: 12,
          }}
        />
        <div
          style={{
            color: 'white',
            fontSize: 14,
            lineHeight: 1.1,
            paddingTop: 12,
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 12,
          }}
          className="tfont"
        >
          We are a group of software engineers based out of New Orleans, Lousiana with a passion for
          development & design.
        </div>
        <div
          style={{
            color: 'white',
            fontSize: 14,
            lineHeight: 1.1,
            paddingLeft: 14,
            paddingRight: 14,
            paddingBottom: 10,
          }}
          className="tfont"
        >
          We specialize in creating applications for startups and small businesses taking great care
          to craft products that reduce operational and maitenence costs.
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          maxWidth: 616,
          width: '100%',
          justifyContent: 'space-between',
          flexWrap: 'wrap',
          marginTop: 16,
        }}
      >
        <div
          style={{
            width: '47.5%',
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontSize: 18,
            color: 'white',
            textShadow: '1px 1px 2px rgba(0,0,0,.17)',
            background: 'rgba(0,0,0,.55)',
            borderRadius: 10,
            paddingTop: 6,
            paddingBottom: 8,
            boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
          }}
        >
          <img
            alt=""
            src="/Imgs/jordan.jpeg"
            style={{
              width: width * 0.15,
              height: width * 0.15,
              borderRadius: '50%',
              marginBottom: 5,
              marginTop: 5,
            }}
          />
          Jordan Fonthiem
        </div>
        <div
          style={{
            width: '47.5%',
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontSize: 18,
            color: 'white',
            textShadow: '1px 1px 2px rgba(0,0,0,.17)',
            background: 'rgba(0,0,0,.55)',
            borderRadius: 10,
            paddingTop: 6,
            paddingBottom: 8,
            boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
          }}
        >
          <img
            alt=""
            src="/Imgs/harry.png"
            style={{
              width: width * 0.15,
              height: width * 0.15,
              borderRadius: '50%',
              marginBottom: 5,
              marginTop: 5,
            }}
          />
          Harry Fox
        </div>
        <div
          style={{
            width: '47.5%',
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            fontSize: 18,
            color: 'white',
            textShadow: '1px 1px 2px rgba(0,0,0,.17)',
            background: 'rgba(0,0,0,.55)',
            borderRadius: 10,
            paddingTop: 6,
            paddingBottom: 8,
            boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
          }}
          ref={reedRed}
        >
          <img
            alt=""
            src="/Imgs/reed.jpeg"
            style={{
              width: width * 0.15,
              height: width * 0.15,
              borderRadius: '50%',
              marginBottom: 5,
              marginTop: 5,
            }}
          />
          Reed Stephens
        </div>
        <div style={{ width: '47.5%', height: '100%' }}>
          <div
            style={{
              width: '100%',
              height: 44,
              marginBottom: 10,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              fontSize: 16,
              color: 'white',
              textShadow: '1px 1px 2px rgba(0,0,0,.17)',
              background: 'rgba(0,0,0,.55)',
              borderRadius: 10,
              boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
              textAlign: 'center',
              fontWeight: 600,
              flexShrink: 0,
            }}
            className="jc-ac"
          >
            Speak To Us More
          </div>
          <div className="jb-ac">
            <div
              style={{
                width: '47%',
                height: buttonHeight,
                marginBottom: 16,
                color: 'white',
                textShadow: '1px 1px 2px rgba(0,0,0,.17)',
                background: 'rgba(0,0,0,.55)',
                borderRadius: 10,
                boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
              }}
              className="jc-ac"
              onTouchEnd={() => handleTouch(5)}
            >
              <BsTelephoneFill />
            </div>
            <div
              style={{
                width: '47%',
                height: buttonHeight,
                marginBottom: 16,
                color: 'white',
                textShadow: '1px 1px 2px rgba(0,0,0,.17)',
                background: 'rgba(0,0,0,.55)',
                borderRadius: 10,
                boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
              }}
              className="jc-ac"
              onTouchEnd={() => handleTouch(6)}
            >
              <BsFillChatFill />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: '100%',
          maxWidth: 616,
          fontSize: 16,
          fontWeight: 600,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          textShadow: '1px 1px 2px rgba(0,0,0,.17)',
          background: 'rgba(0,0,0,.55)',
          borderRadius: 10,
          paddingTop: 6,
          paddingBottom: 8,
          boxShadow: '2px 2px 11px rgba(0,0,0,.17)',
        }}
      >
        Made in <LaSVG color="#fff" width={30} /> with ❤️
      </div>
    </div>
  );
};

const FakeButton = () => {
  const { width } = useApp();
  return (
    <div
      style={{
        width: width * 0.21,
        maxWidth: 94,
        marginBottom: 16,
        color: 'white',
        fontSize: 12,
        flexDirection: 'column',
        textShadow: '1px 1px 1px rgba(0,0,0,.48)',
        alignItems: 'center',
        display: 'flex',
      }}
    />
  );
};

const CallScreen = ({ showPhone, handleClick }) => {
  const { width } = useApp();

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'black',
        zIndex: showPhone ? 10 : -1,
        opacity: showPhone ? 1 : 0,
        transition: 'all 300ms ease',
        flexDirection: 'column',
        paddingTop: '15%',
        paddingBottom: '8%',
      }}
      className="jb-ac"
    >
      <div>
        <div style={{ fontSize: 32, color: 'white' }}>(504) 233-8148</div>
        <div style={{ fontSize: 14, color: '#5B99D1', textAlign: 'center' }}>
          Click below to call us!
        </div>
      </div>
      <div style={{ width: '68%', maxWidth: 260 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          {numbers?.map((n, key) => (
            <div
              style={{
                width: width * 0.19,
                maxWidth: 76,
                maxHeight: 76,
                height: width * 0.19,
                borderRadius: '50%',
                background: '#cccdd1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                fontSize: n.n === '*' ? 42 : 24,
                fontWeight: 500,
                marginBottom: 10,
              }}
              key={key}
            >
              <div style={{ marginTop: n.n === '*' ? 10 : 0 }}>{n.n}</div>
              <div style={{ fontSize: 10, height: n.n === '*' || n.n === '#' ? 0 : 10 }}>{n.s}</div>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <a
            href="tel:+15042338148"
            style={{
              width: width * 0.19,
              height: width * 0.19,
              maxWidth: 76,
              maxHeight: 76,
              borderRadius: '50%',
              background: '#5B99D1',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <BsTelephoneFill style={{ color: 'white', fontSize: 24, marginTop: 2 }} />
          </a>
        </div>
      </div>
      <div onTouchStart={() => handleClick(5)}>
        <BsHouse style={{ color: 'white', fontSize: 30 }} />
      </div>
    </div>
  );
};
