import { useState, useEffect } from 'react';
import { BsTelephoneFill } from 'react-icons/bs';
import { useApp } from '../context';
import { useTransitions } from '../hooks';
import { sendEmail } from '../api/sendEmail';

const apiPass = process.env.REACT_APP_API_PASS;

const Contact = () => {
  const { contactScreen1, contactScreen2, contactScreen3 } = useTransitions();
  const {
    phoneHeight,
    contactLeft,
    phoneWidth,
    show6,
    firstLeft,
    leftoverVertical,
    width,
    phoneNumber,
    page,
    buttonSize,
  } = useApp();
  const isBig = width > 1150;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');

  // useEffect(() => {
  //   clearForms();
  // }, []);
  //
  // const clearForms = () => {
  //   setName('');
  //   setEmail('');
  //   setMsg('');
  //   setSuccess('');
  //   setLoading(false);
  // };

  const sendMsg = async () => {
    setLoading(true);
    let validEmail = validateEmail(email);
    if (email && name && msg && validEmail) {
      const data = {
        subject: 'new message on frobofox.com',
        email: 'harry@bloksapps.com',
        content: `<p>${name} sent a message on frobofox.com saying ${msg}. Their email is ${email}</p>`,
        pass: apiPass,
      };
      let res = await sendEmail(data);
      if (res === 200) {
        setLoading(false);
        setSuccess('We recieved your message! Someone on our team will reach out to you ASAP');
      } else {
        setLoading(false);
        setSuccess(
          'There was an error sending your message, please try again or reach out to us by phone.'
        );
      }
    } else {
      setLoading(false);
      if (!email) {
        setSuccess('Please add an email address');
      } else if (!name) {
        setSuccess('Please add your name');
      } else if (!msg) {
        setSuccess('Please add a message');
      } else if (!validEmail) {
        setSuccess('Hmm something seems off with your email address');
      }
    }
  };

  return (
    <>
      <img
        alt=""
        src="/Imgs/phone.png"
        style={{
          height: phoneHeight,
          left: contactLeft * 2 + phoneWidth,
          top: '50%',
          transform: !show6 ? 'translate(0%, 150%)' : 'translate(0%, -55%)',
          transition: contactScreen1(),
        }}
        className="phone"
      />
      <img
        alt=""
        src="/Imgs/phone.png"
        style={{
          height: phoneHeight,
          left: contactLeft,
          top: '50%',
          transform: !show6 ? 'translate(0%, 140%)' : 'translate(0%, -45%)',
          transition: contactScreen2(),
        }}
        className="phone"
      />

      <div
        className="phone-inner"
        style={{
          zIndex: 3,
          height: phoneHeight - 10,
          width: phoneWidth,
          left: contactLeft * 2 + phoneWidth,
          top: '50%',
          transform: !show6 ? 'translate(0%, 150%)' : 'translate(0%, -55%)',
          transition: contactScreen1(),
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: '7.5%',
          paddingBottom: '7.5%',
        }}
      >
        <div
          style={{ color: '#5B99D1', fontSize: 40, textShadow: '1px 1px 2px rgba(0,0,0,.17)' }}
          className="tfont"
        >
          FROBOFOX
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <input
            style={{
              width: '80%',
              height: 44,
              borderRadius: 10,
              background: 'rgba(0,0,0,.15)',
              border: 'none',
              outline: 'none',
              marginBottom: 12,
              paddingLeft: 14,
            }}
            placeholder="NAME"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            style={{
              width: '80%',
              height: 44,
              borderRadius: 10,
              background: 'rgba(0,0,0,.15)',
              border: 'none',
              outline: 'none',
              marginBottom: 12,
              paddingLeft: 14,
            }}
            placeholder="EMAIL"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            style={{
              width: '80%',
              height: 144,
              borderRadius: 10,
              background: 'rgba(0,0,0,.15)',
              border: 'none',
              outline: 'none',
              marginBottom: 12,
              paddingLeft: 14,
            }}
            placeholder="MESSAGE"
            value={msg}
            onChange={(e) => setMsg(e.target.value)}
          />
          <div
            style={{
              width: '80%',
              height: 44,
              background: '#5B99D1',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: 10,
              fontSize: 22,
              opacity: loading ? 0.5 : 1,
            }}
            className="tfont"
            onClick={() => sendMsg()}
          >
            {loading ? 'Loading..' : 'Write Us'}
          </div>
          <div
            style={{
              fontSize: 12,
              textAlign: 'center',
              paddingLeft: 20,
              paddingRight: 20,
              paddingTop: 10,
            }}
          >
            {success}
          </div>
        </div>
      </div>

      <div
        style={{
          position: 'absolute',
          left: firstLeft,
          top: leftoverVertical,
          width: width - firstLeft,
          transform: page === 'contact' ? 'translate(0px, 0px)' : 'translate(0px, 20px)',
          opacity: page === 'contact' ? 1 : 0,
          transition: contactScreen3(),
        }}
      >
        <div
          style={{ color: '#5B99D1', fontSize: 40, textShadow: '1px 1px 2px rgba(0,0,0,.17)' }}
          className="tfont"
        >
          Contact Us
        </div>
        <div
          style={{
            color: '#353538',
            fontSize: isBig ? 20 : 18,
            lineHeight: 1.1,
            paddingRight: isBig ? '10%' : '7%',
          }}
          className="tfont"
        >
          We are excited to hear about your next project! Let's get started by talking more. We are
          happy to create a quote or answer any questions you may have.
        </div>
      </div>

      <div
        className="phone-inner"
        style={{
          height: phoneHeight - 10,
          width: phoneWidth,
          left: contactLeft,
          top: '50%',
          transform: !show6 ? 'translate(0%, 140%)' : 'translate(0%, -45%)',
          transition: contactScreen2(),
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <div style={{ marginTop: '20%' }}>
          <div style={{ fontSize: 28 }}>{phoneNumber}</div>
          <div style={{ fontSize: 14, color: '#5B99D1', textAlign: 'center' }}>
            Click below to call us!
          </div>
        </div>
        <div style={{ width: '70%', marginBottom: '14%' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {numbers?.map((n, key) => (
              <div
                style={{
                  width: buttonSize,
                  height: buttonSize,
                  borderRadius: '50%',
                  background: 'rgba(0,0,0,.15)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                  fontSize: n.n === '*' ? 42 : 24,
                  fontWeight: 500,
                  marginBottom: 10,
                }}
                key={key}
              >
                <div style={{ marginTop: n.n === '*' ? 10 : 0 }}>{n.n}</div>
                <div style={{ fontSize: 10, height: n.n === '*' || n.n === '#' ? 0 : 10 }}>
                  {n.s}
                </div>
              </div>
            ))}
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                width: buttonSize,
                height: buttonSize,
                borderRadius: '50%',
                background: '#5B99D1',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <BsTelephoneFill style={{ color: 'white', fontSize: width > 1150 ? 26 : 22 }} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;

const numbers = [
  { n: 1, s: '' },
  { n: 2, s: 'ABC' },
  { n: 3, s: 'DEF' },
  { n: 4, s: 'GHI' },
  { n: 5, s: 'JKL' },
  { n: 6, s: 'MNO' },
  { n: 7, s: 'PQRS' },
  { n: 8, s: 'TUV' },
  { n: 9, s: 'WXYZ' },
  { n: '*', s: '' },
  { n: 0, s: '+' },
  { n: '#', s: '' },
];

function validateEmail(email) {
  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regEmail.test(email)) {
    return false;
  } else {
    return true;
  }
}
