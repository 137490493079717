import { useApp } from '../../../context'
import PhoneHeader from '../../PhoneHeader'
import { useTransitions } from '../../../hooks'

const HomeCenterScreen = () => {
  const { homeScreen3 } = useTransitions();
  const { show3, phoneWidth, phoneHeight, width, setPage } = useApp();
  const isBig = width > 1150
  const h = isBig ? phoneHeight - 10 : 576
  const w = isBig ? phoneWidth - 10 : 280

  return(
    <div className='phone-inner' style={{height: h, width: w, opacity: show3 ? 1 : 0, left: '50%', top: '50%', transform: !show3 ? 'translate(-50%, 150%)' : 'translate(-50%, -50%)', transition: homeScreen3(), zIndex: 3}}>
      <PhoneHeader />
      <img alt='' src='/Imgs/mobile2.png' style={{width: '95%', marginTop: 40, marginLeft: '2.5%'}}/>
      <div style={{fontSize: width > 1150 ? 40 : 34, paddingTop: 20, color: '#000', textAlign: 'center', lineHeight: 1.1, transform: 'rotate(-4deg)', textShadow: '1px 1px 2px rgba(91, 153, 209,.37)'}} className='tfont'>Software Development <br /><span style={{fontStyle: 'italic'}}>Made Easy</span></div>
      <div style={{width: '80%', height: 44, background: '#5B99D1', marginLeft: '10%', borderRadius: 16, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 24, color: 'white', marginTop: width > 1150 ? 112 : 56, boxShadow: '2px 2px 14px rgba(0,0,0,.12)'}} className='tfont' onClick={() => setPage('contact')}>Launch Today</div>
    </div>
  )
}

export default HomeCenterScreen;
