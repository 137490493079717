import { useApp } from '../../../context'
import { useTransitions } from '../../../hooks'

const HomeMachineScreen = () => {
  const { homeScreen4 } = useTransitions();
  const { phoneHeight, phoneWidth, show4, firstLeft, isBig, top2 } = useApp();

  return(
    <div className='phone-inner' style={{height: phoneHeight, width: phoneWidth, opacity: show4 ? 1 : 0, left: firstLeft, top: top2 - 12, transform: !show4 ? 'translate(0%, -250%)' : 'translate(0%, 0%)', transition: homeScreen4()}}>
      <div style={{position: 'relative', height: '100%', flexDirection: 'column', paddingTop: -top2}} className='jc-ac'>
        <img alt='' src='/Imgs/machine.png' style={{width: '100%', marginBottom: 20}}/>
        <div style={{color: '#5B99D1', textAlign: 'center', fontSize: 34, textShadow: '1px 1px 2px rgba(0,0,0,.17)', marginBottom: isBig ? 10 : 0, lineHeight: 1, paddingLeft: isBig ? 0 : 16, paddingRight: isBig ? 0 : 16}} className='tfont'>Machine Learning</div>
      </div>
    </div>
  )
}

export default HomeMachineScreen;
