import { useApp } from '../../../context'
import MobileMenu from '../../Menus/Mobile'
import { useTransitions } from '../../../hooks'

const HomeMobileScreen = () => {
  const { homeScreen2 } = useTransitions();
  const { phoneHeight, phoneWidth, show2, firstLeft, page, top2 } = useApp();

  return(
    <div className='phone-inner' style={{height: phoneHeight, width: phoneWidth, opacity: show2 ? 1 : 0, right: firstLeft, bottom: top2 - 10, transform: !show2 ? 'translate(0%, 150%)' : 'translate(0%, 0%)', transition: homeScreen2(), zIndex: page === 'web' ? 3 : ''}}>
      <div style={{position: 'relative', height: '100%', flexDirection: 'column', paddingBottom: -top2}} className='jc-ac'>
        <img alt='' src='/Imgs/mobile.png' style={{width: '100%', opacity: page === 'web' ? .25 : 1, transition: 'all 650ms ease 600ms'}}/>
        <div style={{color: 'rgba(91, 153, 209, .9)', textAlign: 'center', fontSize: 34, textShadow: '1px 1px 2px rgba(0,0,0,.17)', opacity: page === 'web' ? .25 : 1, transition: 'all 650ms ease 600ms'}} className='tfont'>Mobile Apps</div>
        <MobileMenu />
      </div>
    </div>
  )
}

export default HomeMobileScreen;
