import React, { createContext, useContext, useState, useEffect } from 'react';
import { useWindowSizes } from 'react-use-window-sizes';
import { isChrome } from 'react-device-detect';

const SiteContext = createContext();

export default function SiteWrapper({ children }) {
  const [loaded, setLoaded] = useState(false);
  const [page, setPage] = useState('');
  const show1 = loaded && (page === '' || page === 'web' || page === 'mobile');
  const show2 = loaded && (page === '' || page === 'mobile' || page === 'web');
  const show3 = loaded && page === '';
  const show4 = loaded && (page === '' || page === 'machine');
  const show5 =
    loaded && (page === '' || page === 'contact' || page === 'machine' || page === 'about');
  const show6 = loaded && page === 'contact';
  const show7 = loaded && page === 'about';
  const [phoneNumber, setPhoneNumber] = useState('(504) 408-1644');
  const { height, width } = useWindowSizes();
  const [setWidth, setSetWidth] = useState(width);
  const [setHeight, setSetHeight] = useState(height);
  const [resizing, setResizing] = useState(false);
  const phoneWidth = width > 1150 ? 346 : 270;
  const phoneHeight = phoneWidth * 2.023121387283237;
  const halfPhone = phoneWidth / 2;
  const halfHeight = phoneHeight / 2;
  const safeHeight = height > phoneHeight ? height : phoneHeight;
  const quarterPhone = phoneWidth / 4;
  const leftoverVertical = (safeHeight - phoneHeight) / 2;
  const firstLeft = width * 0.75 - quarterPhone;
  const buttonSize = phoneWidth * 0.2;
  const contactLeft = (firstLeft - phoneWidth * 2) / 3;
  const aboutWidth = phoneWidth - 75;
  const aboutHeight = aboutWidth * 2.023121387283237;
  const aboutLeft = (firstLeft - aboutWidth * 3) / 4;
  const aboutTop = (safeHeight - aboutHeight) / 4;
  const [delay, setDelay] = useState(false);
  const isBig = width > 1150;
  const [contactZ, setContactZ] = useState(false);

  const smallSpacer = (height - phoneHeight - 50) / 2;
  const smallSpacer2 = phoneHeight - (height - (phoneWidth + smallSpacer * 2));
  const heightChecker = width > 1150 ? phoneHeight : 600;
  const minHeight = height > heightChecker ? '100vh' : heightChecker;
  const middle = width > 1150 ? phoneHeight : phoneHeight + 50;
  const top1 = width > 1150 ? leftoverVertical : smallSpacer;
  const top2 = width > 1150 ? -halfHeight : -smallSpacer2;

  const filter = isChrome ? 'drop-shadow(2px 2px 14px rgba(0,0,0,.2))' : '';

  useEffect(() => {
    setContactZ(true);
    if (page === 'machine' || page === 'about' || page === 'contact') {
      setTimeout(() => setContactZ(false), 1250);
    } else {
      setTimeout(() => setContactZ(false), 900);
    }
  }, [page]);

  useEffect(() => {
    if (setWidth !== width && loaded) {
      setSetWidth(width);
      setResizing(true);
      setTimeout(() => setResizing(false), 1200);
    }
    if (setHeight !== height && loaded) {
      setSetHeight(height);
      setResizing(true);
      setTimeout(() => setResizing(false), 1200);
    }
    //eslint-disable-next-line
  }, [width, height]);

  const siteState = {
    contactZ,
    isBig,
    filter,
    middle,
    top1,
    top2,
    resizing,
    aboutHeight,
    aboutWidth,
    minHeight,
    smallSpacer,
    smallSpacer2,
    delay,
    setDelay,
    loaded,
    setLoaded,
    page,
    setPage,
    show1,
    show2,
    show3,
    show4,
    show5,
    show6,
    show7,
    phoneNumber,
    setPhoneNumber,
    height,
    width,
    phoneWidth,
    phoneHeight,
    halfPhone,
    halfHeight,
    safeHeight,
    quarterPhone,
    leftoverVertical,
    firstLeft,
    buttonSize,
    contactLeft,
    aboutLeft,
    aboutTop,
  };

  return <SiteContext.Provider value={siteState}>{children}</SiteContext.Provider>;
}

export function useApp() {
  return useContext(SiteContext);
}
