import { useApp } from '../../context'
import { useTransitions } from '../../hooks'

const MobileMenu = () => {
  const { mobileMenu } = useTransitions();
  const { halfHeight, phoneWidth, page, isBig, setPage } = useApp();

  return(
    <div style={{position: 'absolute', left: isBig ? 14 : 6, top: isBig ? 20 : 100, width: '92%', height: halfHeight, transform: page === 'web' ? 'translate(0%, 0%)' : 'translate(0%, -120%)', transition: mobileMenu(), display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', paddingTop: isBig ? '10%' : '6%'}}>
      <div style={{width: phoneWidth * .36, height: phoneWidth * .36, borderRadius: 10, background: 'rgba(91, 153, 209, .9)', marginLeft: '8%', display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}} onClick={() => setPage('')}>
        <img alt='' src='/Imgs/mobile2.png' style={{width: '90%'}}/>
        <div style={{color: 'white', fontSize: 18, textShadow: '1px 1px 2px rgba(0,0,0,.17)'}} className='tfont'>Home</div>
      </div>
      <div style={{width: phoneWidth * .36, height: phoneWidth * .36, borderRadius: 10, background: 'rgba(91, 153, 209, .9)', marginRight: '8%', display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}} onClick={() => setPage('mobile')}>
        <img alt='' src='/Imgs/mobile.png' style={{width: '100%'}}/>
        <div style={{color: 'white', fontSize: 18, textShadow: '1px 1px 2px rgba(0,0,0,.17)', marginTop: 5}} className='tfont'>Mobile</div>
      </div>
      <div style={{width: phoneWidth * .36, height: phoneWidth * .36, borderRadius: 10, background: 'rgba(91, 153, 209, .9)', marginLeft: '8%', display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center'}} onClick={() => setPage('machine')}>
        <img alt='' src='/Imgs/machine.png' style={{width: '80%'}}/>
        <div style={{color: 'white', fontSize: 18, textShadow: '1px 1px 2px rgba(0,0,0,.17)'}} className='tfont'>A.I.</div>
      </div>
      <div style={{width: phoneWidth * .36, height: phoneWidth * .36, marginRight: '8%', display: 'flex', justifyContent:'space-between', flexDirection: 'column'}}>
        <div style={{width: '100%', height: phoneWidth * .17, borderRadius: 10, background: 'rgba(91, 153, 209, .9)', display: 'flex', justifyContent:'center', alignItems: 'center', color: 'white', fontSize: 18, textShadow: '1px 1px 2px rgba(0,0,0,.17)'}} className='tfont' onClick={() => setPage('contact')}>
          Contact
        </div>
        <div style={{width: '100%', height: phoneWidth * .17, borderRadius: 10, background: 'rgba(91, 153, 209, .9)', display: 'flex', justifyContent:'center', alignItems: 'center', color: 'white', fontSize: 18, textShadow: '1px 1px 2px rgba(0,0,0,.17)'}} className='tfont' onClick={() => setPage('about')}>
          About
        </div>
      </div>
      <div style={{width: phoneWidth * .38, height: 38}}></div>
    </div>
  )
}

export default MobileMenu
