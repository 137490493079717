import { useApp } from '../context'
import { useTransitions } from '../hooks'

const Web = () => {
  const { webScreen } = useTransitions();
  const { leftoverVertical, firstLeft, width, page } = useApp();
  const isBig = width > 1150

  return(
    <>
      <div style={{position: 'absolute', paddingLeft: '5%', marginRight: '5%', left: width - firstLeft, top: isBig ? leftoverVertical : '10%', maxWidth: 720, transform: page === 'web' ? 'translate(0px, 0px)' : 'translate(0px, 20px)', opacity: page === 'web' ? 1 : 0, transition: webScreen(), zIndex: page === 'web' ? 10 : -1 }}>
        <div style={{color: '#5B99D1', fontSize: 40, textShadow: '1px 1px 2px rgba(0,0,0,.17)'}} className='tfont'>Website Are Our <span style={{fontStyle: 'italic'}}>Passion</span></div>
        <div style={{color: '#353538', fontSize: 20, lineHeight: 1.1, marginTop: 6}} className='tfont'>We specailize in creating custom web apps for marketplaces, e-commerce, blockchain, finance, no-code and more. Our focus is on building stable sites in ways that set our clients up for success and on completing jobs ontime at industry low costs. </div>
        <div style={{color: '#353538', fontSize: 20, lineHeight: 1.1, marginTop: 16}} className='tfont'>Check out a few of our past projects</div>
        <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: 28}}>
          <a href='https://bloksapps.com' style={{width: '45%', marginBottom: 32}} rel="noopener noreferrer" target='blank' className='bounce'>
            <img alt='' src='/Imgs/bloks.png' style={{width: '100%', borderRadius: 15}} />
          </a>
          <a href='https://wordleweasel.com' style={{width: '45%', marginBottom: 32}} rel="noopener noreferrer" target='blank' className='bounce'>
            <img alt='' src='/Imgs/weasel.png' style={{width: '100%', borderRadius: 15}} />
          </a>
          <a href='https://stockbloks.com' style={{width: '45%', marginBottom: 32}} rel="noopener noreferrer" target='blank' className='bounce'>
            <img alt='' src='/Imgs/sbloks.png' style={{width: '100%', borderRadius: 15}} />
          </a>
          <a href='https://shamancoin.org' style={{width: '45%', marginBottom: 32}} rel="noopener noreferrer" target='blank' className='bounce'>
            <img alt='' src='/Imgs/shaman.png' style={{width: '100%', borderRadius: 15}} />
          </a>
        </div>
      </div>
    </>
  )
}

export default Web
