import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ROUTE;

export const sendEmail = (data) => {
  return axios
    .post(API_URL + 'email', data)
    .then((response) => {
      return response?.status;
    })
    .catch((e) => {
      return 'error';
    });
};
