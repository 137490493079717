import { useState, useEffect } from 'react';
import { useCurrentTime } from 'react-use-current-time';
import { useWindowSizes } from 'react-use-window-sizes';
import LightningIcon from './lightning';
import BatteryTip from './tip';
import { isChrome, isEdge, isOpera } from 'react-device-detect';

const PhoneHeader = () => {
  const { width } = useWindowSizes();
  const { time } = useCurrentTime();

  const [batteryCharging, setBatteryCharging] = useState(false);
  const [batteryLevel, setBatteryLevel] = useState(false);

  const handleBattery = () => {
    if (isChrome || isEdge || isOpera) {
      let batteryPromise = navigator?.getBattery();
      batteryPromise.then(batteryCallback);

      function batteryCallback(batteryObject) {
        printBatteryStatus(batteryObject);
      }
      function printBatteryStatus(batteryObject) {
        setBatteryCharging(batteryObject.charging);
        setBatteryLevel(batteryObject.level);
      }
    } else {
      setBatteryLevel(.75)
    }
  }

  useEffect(() => {
    setTimeout(() => handleBattery(), 300);
    //eslint-disable-next-line
  }, [isChrome, isEdge, isOpera])

  return(
    <div style={{width: '100%', height: width > 1150 ? 86 : 80, boxShadow: '0px 2px 8px rgba(0,0,0,.15)', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingTop: 1, flexDirection: 'column'}}>
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', color: 'rgba(0,0,0,.9)', width: width > 1150 ? '72%' : '78%', fontSize: 10, fontWeight: 400, paddingBottom: width > 1150 ? 17 : 13}}>
        <div style={{paddingLeft: 4}}>{time}</div>
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative'}}>
          {Math.round(batteryLevel * 100)}%
          <div style={{width: 15, height: 9, borderRadius: 3, border: '1px solid rgba(0,0,0,.8)', padding: 1, marginLeft: 4}}>
            <div style={{width: `${batteryLevel * 100}%`, height: '100%', background: batteryLevel < .21 ? 'rgba(255, 0, 0, 1)' : 'rgba(0,0,0,.9)', borderRadius: 2}} />
          </div>
          <BatteryTip />
          <div style={{position: 'absolute', top: '50%', right: -4, transform: 'translate(-64%, -44%)', display: batteryCharging ? '' : 'none'}}>
            <LightningIcon />
          </div>
        </div>
      </div>
      <div style={{fontSize: 34, letterSpacing: '-.5mm', color: '#5B99D1', lineHeight: 1, paddingBottom: 8, textShadow: '1px 1px 2px rgba(0,0,0,.17)'}} className='tfont'>FROBOFOX</div>
    </div>
  )
}

export default PhoneHeader
