import { useState, useLayoutEffect } from 'react';
import { BsArrowUpShort, BsChevronLeft } from 'react-icons/bs';
import { sendEmail } from '../../api/sendEmail';

const apiPass = process.env.REACT_APP_API_PASS;

const MessageScreen = ({ showMessage, handleClick }) => {
  const [tempMsg, setTempMsg] = useState('');
  const [newMsg, setNewMsg] = useState([]);
  const [showResponse, setShowResponse] = useState(false);
  const [showError, setErrorResponse] = useState(false);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const [resizing, setResizing] = useState(false);

  const handleSend = async () => {
    setNewMsg([...newMsg, tempMsg]);
    const data = {
      subject: 'new message on frobofox.com',
      email: 'harry@bloksapps.com',
      content: `<p>New message on frobofox.com saying ${tempMsg}.</p>`,
      pass: apiPass,
    };
    let res = await sendEmail(data);
    if (res === 200) {
      setTimeout(() => setErrorResponse(false), 100);
      setTimeout(() => setShowResponse(true), 800);
      setTimeout(() => setTempMsg(''), 100);
    } else {
      setTimeout(() => setShowResponse(false), 100);
      setTimeout(() => setErrorResponse(true), 800);
      setTimeout(() => setTempMsg(''), 100);
    }
  };

  useLayoutEffect(() => {
    window.visualViewport.addEventListener('resize', updateWindowSize);
    updateWindowSize();
    return () => window.visualViewport.removeEventListener('resize', updateWindowSize);
  }, []);

  const updateWindowSize = () => {
    setViewHeight(window.visualViewport.height);
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: viewHeight,
        background: 'black',
        zIndex: showMessage ? 11 : -2,
        opacity: showMessage ? 1 : 0,
        transition: resizing ? 'all 0ms linear' : 'all 300ms ease',
      }}
    >
      <Header handleClick={handleClick} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          width: '100%',
          paddingLeft: 14,
          paddingRight: 14,
          paddingTop: 22,
          height: viewHeight - 146,
          overflow: 'scroll',
        }}
      >
        <div className="frobo-msg">
          What can we help you with?{' '}
          <div style={{ marginTop: 8 }}>Please include your email so we can get back to you</div>
        </div>
        {newMsg && (
          <div className="jc-ae" style={{ flexDirection: 'column', width: '100%' }}>
            {newMsg?.map((mmm, i) => (
              <div key={i} className="cust-msg">
                {mmm}
              </div>
            ))}
          </div>
        )}
        {showResponse && (
          <div className="frobo-msg">
            Thanks! We have recieved your message and will get back to you ASAP
          </div>
        )}
        {showError && (
          <div className="frobo-msg">
            There was an error sending your message, please try again or give us a call.
          </div>
        )}
      </div>
      <Footer
        setTempMsg={setTempMsg}
        tempMsg={tempMsg}
        handleSend={handleSend}
        setResizing={setResizing}
      />
    </div>
  );
};

export default MessageScreen;

const Header = ({ handleClick }) => {
  return (
    <div
      style={{
        width: '100%',
        height: 88,
        boxShadow: '2px 2px 6px rgba(255,255,255,.3)',
        paddingLeft: 14,
        paddingRight: 14,
        flexShrink: 0,
        background: 'black',
      }}
      className="jb-ac"
    >
      <div onTouchStart={() => handleClick(6)}>
        <BsChevronLeft style={{ color: 'white', fontSize: 24 }} />
      </div>
      <div style={{ color: 'white', fontSize: 11, flexDirection: 'column' }} className="jc-ac">
        <div
          style={{
            height: 40,
            width: 40,
            borderRadius: '50%',
            background: '#919294',
            marginBottom: 4,
            fontSize: 22,
            color: 'black',
          }}
          className="jc-ac"
        >
          F
        </div>
        FROBOFOX
      </div>
      <div style={{ width: 24 }}></div>
    </div>
  );
};

const Footer = ({ setTempMsg, tempMsg, handleSend, setResizing }) => {
  const handleFocus = () => {
    setResizing(true);
    setTimeout(() => setResizing(false), 1500);
  };
  return (
    <div style={{ paddingLeft: 14, paddingRight: 14, width: '100%' }}>
      <div
        className="mobile-msg-input-wrap"
        style={{ flexShrink: 0, marginTop: 8, marginBottom: 12 }}
      >
        <input
          placeholder="Message"
          className="mobile-msg-input"
          onChange={(e) => setTempMsg(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleFocus}
          value={tempMsg}
        />
        <div className="mobile-msg-input-btn jc-ac" onTouchStart={handleSend}>
          <BsArrowUpShort style={{ fontSize: 24 }} />
        </div>
      </div>
    </div>
  );
};
