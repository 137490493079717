import { useApp } from '../../context'
import { useTransitions } from '../../hooks'

const HomeFrames = () => {
  const { homeFrame1, homeFrame2, homeFrame3, homeFrame4, homeFrame5 } = useTransitions();
  const { phoneHeight, firstLeft, show1, setPage, show2, show3, show4, show5, middle, top1, top2, filter } = useApp();

  return(
    <>
      <img alt='' src='/Imgs/phone90.png' style={{width: phoneHeight, right: firstLeft, top: top1, transform: !show1 ? `translate(-1000px, 0%)` : `translate(0%, 0%)`, transition: homeFrame1(), opacity: show1 ? 1 : 0, filter: filter}} className='phone90' onClick={() => setPage('web')} />
      <img alt='' src='/Imgs/phone.png' style={{height: phoneHeight, right: firstLeft, bottom: top2, transform: !show2 ? 'translate(0%, 150%)' : 'translate(0%, 0%)', transition: homeFrame2(), opacity: show2 ? 1 : 0, filter: filter}} className='phone' onClick={() => setPage('mobile')} />
      <img alt='' src='/Imgs/phone.png' style={{height: middle, left: '50%', top: '50%', transform: !show3 ? 'translate(-50%, 150%)' : 'translate(-50%, -50%)', transition: homeFrame3(), opacity: show3 ? 1 : 0, filter: filter}} className='phone' />
      <img alt='' src='/Imgs/phone.png' style={{height: phoneHeight, left: firstLeft, top: top2, transform: !show4 ? 'translate(0%, -250%)' : 'translate(0%, 0%)', transition: homeFrame4(), opacity: show4 ? 1 : 0, filter: filter}} className='phone' onClick={() => setPage('machine')} />
      <img alt='' src='/Imgs/phone90.png' style={{width: phoneHeight, left: firstLeft, bottom: top1, transform: !show5 ? `translate(300%, 0%)` : `translate(0px, 0%)`, transition: homeFrame5(), opacity: show5 ? 1 : 0, filter: filter}} className='phone90' />
    </>
  )
}

export default HomeFrames
