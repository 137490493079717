import HomeFrames from '../components/Frames/Home'
import HomeWebScreen from '../components/Screens/Home/Web'
import HomeMobileScreen from '../components/Screens/Home/Mobile'
import HomeCenterScreen from '../components/Screens/Home/Center'
import HomeMachineScreen from '../components/Screens/Home/Machine'
import HomeContactScreen from '../components/Screens/Home/Contact'

function Home() {
  return (
    <>
      <HomeFrames />
      <HomeWebScreen />
      <HomeMobileScreen />
      <HomeCenterScreen />
      <HomeMachineScreen />
      <HomeContactScreen />
    </>
  );
}

export default Home;
