import Web from './Web';
import Home from './Home';
import About from './About';
import Mobile from './Mobile';
import Contact from './Contact';
import Machine from './Machine';
import { useEffect } from 'react';
import { useApp } from '../context';
import SiteSmall from './Small'

function Site() {
  const { minHeight, setLoaded, width } = useApp();

  useEffect(() => {
    setTimeout(() => setLoaded(true), 100)
    //eslint-disable-next-line
  }, [])

  if (width > 850) {
    return (
      <div className="App" style={{minHeight: minHeight}}>
        <Web />
        <Home />
        <About />
        <Mobile />
        <Contact />
        <Machine />
      </div>
    );
  } else {
    return <SiteSmall />
  }
}

export default Site
